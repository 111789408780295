import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { reqGetSystem } from '@/api';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index/'),
    },
    {
        path: '/index_new',
        name: 'index_new',
        component: () => import('@/views/index_new/index.vue'),
    },
    {
        path: '/type/:id',
        name: 'type',
        component: () => import('../views/TypeView.vue'),
    },
    {
        path: '/good/:id',
        name: 'good',
        component: () => import('../views/GoodNewView.vue'),
    },
    {
        path: '/new_good/:id',
        name: 'new_good',
        component: () => import('../views/GoodView.vue'),
    },
    {
        path: '/map/:address',
        name: 'map',
        component: () => import('../views/MapView.vue'),
    },
    {
        path: '/chat/:id',
        name: 'chat',
        component: () => import('../views/ChatView.vue'),
    },
    {
        path: '/car',
        name: 'car',
        component: () => import('../views/ShopCarView.vue'),
    },
    {
        path: '/sign',
        name: 'sign',
        component: () => import('@/views/login'),
    },
    {
        path: '/me',
        name: 'me',
        component: () => import('../views/MeView.vue'),
    },
    {
        path: '/apply',
        name: 'apply',
        component: () => import('../views/ApplyView.vue'),
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/OrderView.vue'),
    },
    {
        path: '/search/',
        name: 'search',
        component: () => import('../views/SearchView.vue'),
    },
    {
        path: '/storeLogin/',
        name: 'storeLogin',
        component: () => import('../views/StoreLogin.vue'),
    },
    {
        path: '/store',
        component: () => import('../App.vue'),
        children: [
            {
                path: 'index/:id',
                name: 'store',
                component: () => import('../views/StoreView.vue'),
            },
            {
                path: 'all/:id',
                name: 'all',
                component: () => import('../views/StoreAllView.vue'),
            },
        ],
    },
    // {
    //     path: '/store/all',
    //     name: 'all',
    //     component: () => import('../views/StoreAllView.vue'),
    //     // children: [
    //     //     {
    //     //         path: 'all',
    //     //         component: () => import('../views/StoreAllView.vue'),
    //     //     },
    //     // ],
    // },
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, _, next) => {
    const { token, userInfo } = store.state.user;
    const data = await reqGetSystem();
    if (data.code == 200) {
        document.title = data.data.system_name;
        store.state.system.system = data.data;
    }
    if (token) {
        await store.dispatch('user/getUserInfo');
    }
    next();
});
export default router;
