import axios from 'axios';
import store from '@/store';
const request = axios.create({
    // baseURL: 'http://x276.www.pusite.icu/api',
    baseURL: 'http://x53.www.wuw.wang/api',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
});
request.interceptors.request.use(config => {
    config.headers['Authorization'] = localStorage.getItem('token');
    return config;
});
request.interceptors.response.use(
    response => {
        return response.data;
    },
    err => {
        return Promise.reject(err);
    }
);
export default request;
