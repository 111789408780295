import { getToken, setToken, removeToken } from '@/utils/token';
import { reqLogin, reqLogout, reqUserInfo } from '@/api';
export default {
    state: {
        token: getToken() || '',
        userInfo: null,
    },

    mutations: {
        setToken(state, token) {
            state.token = token;
            setToken(token);
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        resetUserInfo(state) {
            state.token = '';
            state.userInfo = null;
            removeToken();
        },
    },
    actions: {
        async getToken({ commit }, loginInfo) {
            try {
                const data = await reqLogin(loginInfo);
                if (data.code == 200) {
                    commit('setToken', data.data.token);
                } else {
                    return Promise.reject(data);
                }
                return data;
            } catch (e) {
                return Promise.reject(e);
            }
        },

        async setToken({ commit }, data) {
            try {
                commit('setToken', data.token);
                commit('setUserInfo', data.userInfo);
            } catch (e) {
                return Promise.reject(e);
            }
        },

        async getUserInfo({ commit }) {
            try {
                const re = await reqUserInfo();
                commit('setUserInfo', re.data);
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async logOut({ commit }) {
            try {
                const re = await reqLogout();
                if (re.code == 200) {
                    commit('resetUserInfo');
                }
                return Promise.resolve(re);
            } catch (e) {
                return Promise.reject(e.response.data);
            }
        },
    },
    getters: {},
    namespaced: true,
};
